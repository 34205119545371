:root {
  --c-dark-goldenrod: #b37700;
  --c-gold-tips: #e8ab30;
  --c-venetian-red: #c02;
  --c-dark-pink: #eb4763;
  --c-wood-bark: #1f1a14;
  --c-cement: #998066;
  --c-pampas: #ebe6e0;
  --s-leading: 1.375rem;
  --s-leading-double: calc(1.375rem * 2);
  --s-leading-half: calc(1.375rem * .5);
}

* {
  box-sizing: border-box;
  font-size: inherit;
}

html {
  font-family: Input, Office Code Pro, Source Code Pro, Fira Mono, Inconsolata, Monaco, Consolas, Lucida Console, Liberation Mono, DejaVu Sans Mono, monospace;
  font-size: calc(.869565vw + .956522rem);
  line-height: var(--s-leading);
  color: var(--c-wood-bark);
  background: var(--c-pampas);
  transition: color .5s, background .5s;
}

a {
  color: inherit;
  border-bottom: .1rem dotted #0000;
  text-decoration: none;
}

a:hover {
  border-bottom-color: currentColor;
}

svg {
  shape-rendering: geometricprecision;
  fill: currentColor;
}

body {
  text-align: center;
  margin: 0;
  padding: 0;
}

#status {
  z-index: -1;
  top: var(--s-leading);
  right: var(--s-leading);
  line-height: var(--s-leading-half);
  pointer-events: none;
  position: fixed;
}

#status figure {
  opacity: 0;
  color: var(--c-cement);
  margin: 0;
  padding: 0;
  transition: all 1s;
}

body.is-playing #status-playing, body.is-scrolling #status-scrolling {
  opacity: 1;
}

#intro {
  padding: var(--s-leading-double) var(--s-leading-half);
  display: inline-block;
}

#intro p {
  margin: 0;
  font-weight: bold;
}

#intro p span {
  white-space: nowrap;
}

#intro svg {
  margin-bottom: var(--s-leading);
  width: 100%;
  max-height: 33vh;
  transition: max-height .5s;
}

#log {
  padding: 0 0 var(--s-leading-double);
  margin: 0;
  list-style: none;
}

#log li {
  white-space: pre-line;
}

#log li:not(:first-child) {
  margin-top: var(--s-leading-half);
}

#log neg, #log pos {
  display: inline;
}

#log neg {
  color: var(--c-venetian-red);
}

#log pos {
  color: var(--c-dark-goldenrod);
}

#log em {
  font-weight: bold;
}

#log i, #log em {
  font-style: normal;
}

#log svg {
  height: var(--s-leading);
  vertical-align: top;
  width: 1.125rem;
  display: inline-block;
}

#log svg, #log i {
  margin-right: .125em;
}

#log svg.skull {
  margin-right: .25rem;
}

#outro {
  height: auto;
  max-height: 0;
  padding-bottom: 0;
  transition: all 1s;
  overflow: hidden;
}

#outro button {
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  background: var(--c-pampas);
  box-shadow: 0 0 0 .1rem var(--c-wood-bark);
  border-width: 0;
  border-radius: .35rem;
  margin: 0 auto;
  padding: 0;
  transition: opacity, visibility 1s;
  display: block;
}

#outro button label {
  pointer-events: none;
  color: var(--c-wood-bark);
  background-color: var(--c-pampas);
  box-shadow: 0 0 0 .1rem var(--c-wood-bark);
  border-radius: .3rem;
  margin-bottom: .5rem;
  padding: .25rem .75rem .375rem;
  display: inline-block;
}

#outro button:hover {
  margin-top: .1rem;
}

#outro button:hover label {
  margin-bottom: .4rem;
}

#outro button:active {
  margin-top: .2rem;
}

#outro button:active label {
  margin-bottom: .3rem;
}

body.is-over #outro {
  padding-top: .1rem;
  padding-bottom: calc(var(--s-leading) * 3);
  max-height: 100vh;
}

body.is-over #outro button {
  opacity: 1;
  visibility: visible;
}

@media (prefers-color-scheme: dark) {
  html {
    color: var(--c-pampas);
    background: var(--c-wood-bark);
  }

  #log neg {
    color: var(--c-dark-pink);
  }

  #log pos {
    color: var(--c-gold-tips);
  }

  #outro button {
    background: var(--c-wood-bark);
    box-shadow: 0 0 0 .1rem var(--c-pampas);
  }

  #outro button label {
    color: var(--c-pampas);
    background-color: var(--c-wood-bark);
    box-shadow: 0 0 0 .1rem var(--c-pampas);
  }
}
/*# sourceMappingURL=index.e99d18ce.css.map */
